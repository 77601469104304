.overlap-bg {
  position: absolute;
  width: 100%;
  height: 403px;
  left: 0;
}

.bg {
  position: absolute;
  width: 100%;
  height: 403px;
  top: 0;
  left: 0;
}

.overlap-group-bf {
  position: relative;
  height: 403px;
}

.mask-group {
  position: absolute;
  width: 100%;
  height: 388px;
  top: 15px;
  left: 0;
}

.intersect {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.intersect-wrapper {
  position: absolute;
  width: 100%;
  height: 392px;
  top: 0;
  left: 0;
}

.intersect-wrapper-img{
  width: 100%;
}
