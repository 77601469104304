.ranking-content {
  position: relative;
  height: calc(100vh - 140px);
  top: 60px;
  overflow: hidden;
}

.ranking-content-wrapper {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 10px 15px 90px 15px;
}

.element-ranking .rank-info {
  width: 100%;
}

.element-ranking .name-point {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 24px;
}

.point {
  width: 104px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid;
  border-color: var(--loyalty-colorwhite);
}

.point-text {
  font-family: var(--font-bold-font-family);
  font-weight: var(--font-bold-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-bold-font-size);
  text-align: center;
  letter-spacing: var(--font-bold-letter-spacing);
  font-style: var(--font-bold-font-style);
}

.name {
  font-family: var(--font-h1-font-family);
  font-weight: var(--font-h1-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-h1-font-size);
  letter-spacing: var(--font-h1-letter-spacing);
  line-height: var(--font-h1-line-height);
  white-space: nowrap;
  font-style: var(--font-h1-font-style);
}

.element-ranking .rank-item-bg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.rank-bg-animate {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.element-ranking .swiper-item-ranking {
    width: 40%;
}

.element-ranking .card {
  position: relative;
  height: 410px;
  background: linear-gradient(
          180deg,
          rgb(110.06, 72.42, 10.9) 22.05%,
          rgb(155.71, 115.46, 30.05) 47.5%,
          rgb(110.06, 72.42, 10.9) 73.12%
  );
}

.element-ranking .cards-slider {
  position: absolute;
  width: 393px;
  height: 227px;
  top: 150px;
  left: 0;
  overflow: hidden;
}

.element-ranking .bronze {
  position: absolute;
  width: 157px;
  height: 212px;
  top: 7px;
  left: -246px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid;
  border-color: transparent;
  box-shadow: 0px 4px 4px #0000001a;
  border-image: linear-gradient(to bottom, rgb(218, 218, 218), rgb(190.15, 190.15, 190.15)) 1;
  opacity: 0.3;
}

.element-ranking .overlap-group-wrapper {
  position: relative;
  width: 222px;
  height: 157px;
  top: 32px;
  left: -32px;
  transform: rotate(-90deg);
}

.element-ranking .overlap-group {
  position: relative;
  width: 220px;
  height: 157px;
}

.element-ranking .card-bronze {
  position: absolute;
  width: 157px;
  height: 212px;
  top: -28px;
  left: 36px;
  transform: rotate(90deg);
  object-fit: cover;
}

.element-ranking .text-wrapper {
  top: -42px;
  left: 49px;
  color: #ffffff4c;
  font-size: 214px;
  position: absolute;
  transform: rotate(90deg);
  mix-blend-mode: overlay;
  font-family: "Kapakana-Regular", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.element-ranking .silver {
  position: absolute;
  width: 157px;
  height: 212px;
  top: 7px;
  left: -64px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid;
  border-color: transparent;
  box-shadow: 0px 4px 4px #0000001a;
  border-image: linear-gradient(to bottom, rgb(218, 218, 218), rgb(190.15, 190.15, 190.15)) 1;
  opacity: 0.3;
}

.element-ranking .div-wrapper {
  position: relative;
  width: 212px;
  height: 157px;
  top: 28px;
  left: -28px;
  transform: rotate(-90deg);
}

.element-ranking .overlap-group-2 {
  position: relative;
  height: 157px;
}

.element-ranking .img {
  position: absolute;
  width: 157px;
  height: 212px;
  top: -28px;
  left: 28px;
  transform: rotate(90deg);
  object-fit: cover;
}

.element-ranking .f {
  position: absolute;
  width: 106px;
  height: 156px;
  top: 1px;
  left: 53px;
  transform: rotate(90deg);
  mix-blend-mode: overlay;
}

.element-ranking .gold {
  position: absolute;
  width: 157px;
  height: 212px;
  top: 7px;
  left: 118px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 4px #0000001a;
}

.element-ranking .platinum {
  position: absolute;
  width: 157px;
  height: 212px;
  top: 7px;
  left: 300px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid;
  border-color: transparent;
  box-shadow: 0px 4px 4px #0000001a;
  border-image: linear-gradient(to bottom, rgb(218, 218, 218), rgb(190.15, 190.15, 190.15)) 1;
  opacity: 0.3;
}

.element-ranking .diamond {
  position: absolute;
  width: 157px;
  height: 212px;
  top: 7px;
  left: 482px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid;
  border-color: transparent;
  box-shadow: 0px 4px 4px #0000001a;
  border-image: linear-gradient(to bottom, rgb(218, 218, 218), rgb(190.15, 190.15, 190.15)) 1;
  opacity: 0.3;
}

.element-ranking .card-2 {
  position: relative;
  width: 215px;
  height: 158px;
  top: 28px;
  left: -28px;
  transform: rotate(-90deg);
}

.element-ranking .overlap-group-3 {
  position: relative;
  width: 213px;
  height: 158px;
}

.element-ranking .card-diamond {
  position: absolute;
  width: 158px;
  height: 213px;
  top: -28px;
  left: 28px;
  transform: rotate(90deg);
  object-fit: cover;
}

.element-ranking .text-wrapper-2 {
  top: 60px;
  left: 95px;
  color: #ffffff;
  font-size: 46px;
  position: absolute;
  transform: rotate(90deg);
  mix-blend-mode: overlay;
  font-family: "Kapakana-Regular", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.element-ranking .quyen-loi {
  position: relative;
  width: 100vw;
  height: 210px;
  padding: 24px 15px;
  background-color: var(--loyalty-colorwhite);
  border-radius: 10px;
  margin-top: 25px;
  margin-left: -15px;
}

.element-ranking .rk-benefit {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: width 2s ease-in-out;
}

.element-ranking .rk-benefit-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.element-ranking .p {
  width: 100%;
  text-align: center;
  margin-bottom: 28px;
  font-family: var(--font-h2-font-family);
  font-weight: var(--font-h2-font-weight);
  color: var(--loyalty-colorblack);
  font-size: var(--font-h2-font-size);
  letter-spacing: var(--font-h2-letter-spacing);
  line-height: var(--font-h2-line-height);
  font-style: var(--font-h2-font-style);
}

.element-ranking .image {
  width: 63px;
  height: 63px;
  object-fit: cover;
}

.element-ranking .u-i-khi-mua-v-futa {
  width: 109px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.element-ranking .span {
  font-weight: 700;
}

.element-ranking .text-wrapper-3 {
  font-size: 10px;
}

.element-ranking .u-ti-n-tr-i-nghi-m-t {
  width: 112px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.element-ranking .text-wrapper-4 {
  font-size: 11px;
}

.element-ranking .t-ch-i-m-khi-s-d {
  width: 95px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.element-ranking .group {
  width: 75px;
  height: 62px;
}

.element-ranking .overlap-group-4 {
  position: relative;
  height: 62px;
}

.element-ranking .mask-group {
  position: absolute;
  width: 62px;
  height: 62px;
  top: 0;
  left: 0;
}

.element-ranking .frame {
  position: absolute;
  width: 26px;
  height: 16px;
  top: 8px;
  left: 49px;
  background-color: var(--loyalty-colormain);
  border-radius: 8px;
  overflow: hidden;
}

.element-ranking .text-wrapper-5 {
  position: absolute;
  top: -2px;
  left: 5px;
  font-family: var(--font-h3-font-family);
  font-weight: var(--font-h3-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-h3-font-size);
  text-align: center;
  letter-spacing: var(--font-h3-letter-spacing);
  line-height: var(--font-h3-line-height);
  font-style: var(--font-h3-font-style);
}

.element-ranking .overlap {
  width: 63px;
  height: 63px;
  background-color: var(--loyalty-colorbg-color);
  border-radius: 31.5px;
}

.element-ranking .image-2 {
  width: 63px;
  height: 63px;
  mix-blend-mode: multiply;
  object-fit: cover;
}

.element-ranking .polygon {
  position: absolute;
  top: -10px;
  width: 100%;
  left: -5px;
}

.polygon-img {
  width: 26px;
  height: 14px;
  margin: 0 auto;
}

.element-ranking .rank-bar {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}

.element-ranking .benefit-add {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.element-ranking .benefit-add-text {
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
}

.element-ranking .rank-bar-img {
  margin: 0 auto;
}

.element-ranking .overlap-2 {
  position: absolute;
  width: 326px;
  height: 14px;
  top: 17px;
  left: 33px;
}

.element-ranking .rectangle {
  width: 323px;
  background-color: var(--loyalty-colorsub-2);
  position: absolute;
  height: 6px;
  top: 4px;
  left: 1px;
  border-radius: 3px;
}

.element-ranking .rectangle-2 {
  width: 186px;
  background-color: var(--loyalty-colormain);
  position: absolute;
  height: 6px;
  top: 4px;
  left: 1px;
  border-radius: 3px;
}

.element-ranking .ellipse {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0;
  left: 0;
  background-color: var(--loyalty-colormain);
  border-radius: 7px;
}

.element-ranking .ellipse-2 {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0;
  left: 78px;
  background-color: var(--loyalty-colormain);
  border-radius: 7px;
}

.element-ranking .ellipse-3 {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0;
  left: 156px;
  background-color: var(--loyalty-colormain);
  border-radius: 7px;
}

.element-ranking .ellipse-4 {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0;
  left: 234px;
  background-color: var(--loyalty-colorsub-2);
  border-radius: 7px;
}

.element-ranking .ellipse-5 {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0;
  left: 312px;
  background-color: var(--loyalty-colorsub-2);
  border-radius: 7px;
}

.element-ranking .vector {
  left: 3px;
  position: absolute;
  width: 7px;
  height: 6px;
  top: 4px;
}

.element-ranking .vector-2 {
  left: 81px;
  position: absolute;
  width: 7px;
  height: 6px;
  top: 4px;
}

.element-ranking .ellipse-6 {
  position: absolute;
  width: 6px;
  height: 6px;
  top: 4px;
  left: 160px;
  background-color: var(--loyalty-colorwhite);
  border-radius: 3px;
}

.element-ranking .text-wrapper-8 {
  top: 35px;
  left: 36px;
  position: absolute;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffffff;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .text-wrapper-9 {
  left: 102px;
  position: absolute;
  top: 35px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffffff;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .text-wrapper-10 {
  left: 180px;
  position: absolute;
  top: 35px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffffff;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .text-wrapper-11 {
  left: 257px;
  position: absolute;
  top: 35px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffffff;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .text-wrapper-12 {
  left: 332px;
  position: absolute;
  top: 35px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffffff;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .isolation-mode {
  position: absolute;
  width: 18px;
  height: 15px;
  top: -1px;
  left: 343px;
}

.element-ranking .text-wrapper-13 {
  top: -4px;
  left: 27px;
  position: absolute;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffffff;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .text-wrapper-14 {
  top: -4px;
  left: 107px;
  position: absolute;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffffff;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .text-wrapper-15 {
  position: absolute;
  top: -4px;
  left: 251px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffffff;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .btn {
  all: unset;
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 20px;
  top: -17px;
  left: 147px;
  border-radius: 15px;
}

.element-ranking .overlap-group-5 {
  position: relative;
  width: 97px;
  height: 25px;
  top: 5px;
  left: 1px;
  background-image: url(../../../../public/img/ranking/union.svg);
  background-size: 100% 100%;
}

.element-ranking .text-wrapper-16 {
  position: absolute;
  width: 85px;
  top: 2px;
  left: 7px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--loyalty-colorwhite);
  font-size: 10px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.element-ranking .lich-su-tich-diem {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  img {
    margin: 0 auto;
  }
}

.element-ranking .text-wrapper-17 {
  position: absolute;
  width: 282px;
  top: 9px;
  left: 35px;
  font-family: var(--font-h2-font-family);
  font-weight: var(--font-h2-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-h2-font-size);
  letter-spacing: var(--font-h2-letter-spacing);
  line-height: var(--font-h2-line-height);
  font-style: var(--font-h2-font-style);
}

.element-ranking .text-wrapper-18 {
  position: absolute;
  top: 38px;
  left: 29px;
  font-family: var(--font-h1-font-family);
  font-weight: var(--font-h1-font-weight);
  color: #ffecab;
  font-size: var(--font-h1-font-size);
  letter-spacing: var(--font-h1-letter-spacing);
  line-height: var(--font-h1-line-height);
  white-space: nowrap;
  font-style: var(--font-h1-font-style);
}

.element-ranking .text-wrapper-19 {
  position: absolute;
  top: 42px;
  left: 60px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffecab;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .text-wrapper-20 {
  position: absolute;
  top: 38px;
  left: 167px;
  font-family: "Inter", Helvetica;
  font-weight: 700;
  color: #ffecab;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.element-ranking .text-wrapper-21 {
  position: absolute;
  top: 42px;
  left: 233px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffecab;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.element-ranking .line {
  position: absolute;
  width: 1px;
  height: 22px;
  top: 41px;
  left: 149px;
}

.element-ranking .frame-3 {
  position: absolute;
  width: 33px;
  height: 33px;
  top: 20px;
  left: 321px;
}

.element-ranking .group-2 {
  position: absolute;
  width: 22px;
  height: 21px;
  top: 7px;
  left: 9px;
}
