.benefit {
    width: 100%;
    border-radius: 23px;
    margin: 22px auto;
    position: relative;
}

.benefit .benefit-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

}

.benefit .benefit-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.benefit .benefit-title {
    text-align: center;
    font-family: var(--font-h2-font-family);
    font-weight: var(--font-h2-font-weight);
    color: var(--loyalty-colorblack);
    font-size: var(--font-h2-font-size);
    letter-spacing: var(--font-h2-letter-spacing);
    line-height: var(--font-h2-line-height);
    font-style: var(--font-h2-font-style);
    margin-bottom: 28px;
}

.benefit .image {
    width: 63px;
    height: 63px;
    object-fit: cover;
}

.benefit .u-i-khi-mua-v-futa {
    width: 109px;
    font-family: "Inter", Helvetica;
    font-weight: 400;
    color: #000000;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
}

.benefit .span {
    font-weight: 700;
}

.benefit .text-wrapper-2 {
    font-size: 10px;
}

.benefit .u-ti-n-tr-i-nghi-m-t {
    width: 112px;
    font-family: "Inter", Helvetica;
    font-weight: 400;
    color: #000000;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
}

.benefit .text-wrapper-3 {
    font-size: 11px;
}