.history-content {
  position: relative;
  height: calc(100vh - 140px);
  top: 60px;
  overflow: hidden;
}

.history-content-wrapper {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px 15px 90px 15px;
}

.element-lich-su-thuong-hieu .history-content-wrapper {
  padding: 0;
}

.element-lich-su-thuong-hieu .content {
  display: inline-flex;
  flex-direction: column;
  height: 671px;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
  position: absolute;
  top: 91px;
  left: 0;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
}

.element-lich-su-thuong-hieu .filter {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  background-color: var(--loyalty-colorwhite);
  overflow: hidden;
  overflow-x: scroll;
  padding: 20px 14px;
}

.element-lich-su-thuong-hieu .filter-item {
  border-radius: 16px;
  border: 1px solid;
  text-align: center;
  white-space: nowrap;
  padding: 4px 8px;
  height: 32px;
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: var(--loyalty-colordark-gery);
  font-size: var(--font-normal-text-font-size);
  letter-spacing: var(--font-normal-text-letter-spacing);
  font-style: var(--font-normal-text-font-style);
}

.element-lich-su-thuong-hieu .filter-active {
  border-color: var(--loyalty-colormain);
  color: var(--loyalty-colorsub-3);
  font-weight: var(--font-h3-font-weight);
}

.element-lich-su-thuong-hieu .all {
  position: relative;
  width: 86px;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--loyalty-colormain);
}

.element-lich-su-thuong-hieu .div {
  position: absolute;
  width: 85px;
  top: 7px;
  left: 1px;
  font-family: var(--font-h3-font-family);
  font-weight: var(--font-h3-font-weight);
  color: var(--loyalty-colorsub-3);
  font-size: var(--font-h3-font-size);
  text-align: center;
  letter-spacing: var(--font-h3-letter-spacing);
  line-height: var(--font-h3-line-height);
  font-style: var(--font-h3-font-style);
}

.element-lich-su-thuong-hieu .cong-diem {
  position: relative;
  width: 108px;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--loyalty-colordark-gery);
}

.element-lich-su-thuong-hieu .text-wrapper-2 {
  position: absolute;
  width: 107px;
  top: 7px;
  left: 1px;
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: var(--loyalty-colordark-gery);
  font-size: var(--font-normal-text-font-size);
  text-align: center;
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-style: var(--font-normal-text-font-style);
}

.element-lich-su-thuong-hieu .div-wrapper {
  width: 124px;
  position: relative;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--loyalty-colordark-gery);
}

.element-lich-su-thuong-hieu .text-wrapper-3 {
  position: absolute;
  width: 123px;
  top: 7px;
  left: 1px;
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: var(--loyalty-colordark-gery);
  font-size: var(--font-normal-text-font-size);
  text-align: center;
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-style: var(--font-normal-text-font-style);
}

.element-lich-su-thuong-hieu .cong-diem-2 {
  width: 110px;
  position: relative;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--loyalty-colordark-gery);
}

.element-lich-su-thuong-hieu .text-wrapper-4 {
  position: absolute;
  width: 109px;
  top: 7px;
  left: 1px;
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: var(--loyalty-colordark-gery);
  font-size: var(--font-normal-text-font-size);
  text-align: center;
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-style: var(--font-normal-text-font-style);
}

.element-lich-su-thuong-hieu .history-list {
  position: relative;
  width: 100%;
  padding: 10px 24px 10px 14px;
}

.element-lich-su-thuong-hieu .title {
  position: relative;
  background-color: var(--loyalty-colorlight-grey);
  padding: 10px 0 10px 20px;
}

.element-lich-su-thuong-hieu .sub-title {
  display: flex;
  background-color: #bebebe21;
  justify-content: space-between;
  padding: 10px 12px 10px 20px;
}

.element-lich-su-thuong-hieu .text-wrapper-5 {
  font-family: var(--font-h3-font-family);
  font-weight: var(--font-h3-font-weight);
  color: var(--loyalty-colorblack);
  font-size: var(--font-h3-font-size);
  letter-spacing: var(--font-h3-letter-spacing);
  line-height: var(--font-h3-line-height);
  font-style: var(--font-h3-font-style);
}

.element-lich-su-thuong-hieu .chi-tiet-su-dung {
  position: relative;
  width: 393px;
  height: 70px;
}

.element-lich-su-thuong-hieu .frame {
  position: absolute;
  width: 55px;
  height: 55px;
  top: 6px;
  left: 14px;
  background-color: var(--loyalty-colorwhite);
  border-radius: 28px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--loyalty-colorgrey);
}

.element-lich-su-thuong-hieu .image {
  position: absolute;
  width: 45px;
  height: 33px;
  top: 10px;
  left: 4px;
  object-fit: cover;
}

.element-lich-su-thuong-hieu .FUTA-bus-lines-ho-n {
  position: absolute;
  top: 8px;
  left: 83px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: transparent;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.element-lich-su-thuong-hieu .span {
  font-weight: var(--font-bold-font-weight);
  color: #000000;
  font-family: var(--font-bold-font-family);
  font-style: var(--font-bold-font-style);
  letter-spacing: var(--font-bold-letter-spacing);
  line-height: var(--font-bold-line-height);
  font-size: var(--font-bold-font-size);
}

.element-lich-su-thuong-hieu .text-wrapper-6 {
  color: #000000;
  font-size: var(--font-small-text-font-size);
  font-family: var(--font-small-text-font-family);
  font-style: var(--font-small-text-font-style);
  font-weight: var(--font-small-text-font-weight);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
}

.element-lich-su-thuong-hieu .text-wrapper-7 {
  color: #9e9e9e;
  font-size: var(--font-small-text-font-size);
  font-family: var(--font-small-text-font-family);
  font-style: var(--font-small-text-font-style);
  font-weight: var(--font-small-text-font-weight);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
}

.element-lich-su-thuong-hieu .text-wrapper-8 {
  position: absolute;
  top: 24px;
  left: 305px;
  font-family: var(--font-h3-font-family);
  font-weight: var(--font-h3-font-weight);
  color: var(--loyalty-colormain);
  font-size: var(--font-h3-font-size);
  letter-spacing: var(--font-h3-letter-spacing);
  line-height: var(--font-h3-line-height);
  font-style: var(--font-h3-font-style);
}
