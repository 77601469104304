.section {

}

.section-title {
  display: flex;
  align-items: center;
}

.section-title-label {
  font-size: 1rem;
  font-weight: 700;
  margin-right: 1rem;
}
.section-title-icon {
  width: 14px;
  height: 12px;
}

.swiper-slide-fp {
  width: 80% !important;
}