.content-qr-wrapper {
    position: relative;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 10px 15px 90px 15px;
}

.content-qr {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 10px;
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    background: linear-gradient(180deg, rgb(0, 159.25, 100.15) 0%, rgb(0, 97, 61) 100%);
    top: 60px;
    overflow: hidden;
}

.content-qr .frame {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 661px;
    align-items: flex-start;
    position: relative;
    margin: 0 auto;
}

.content-qr .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    align-self: stretch;
    width: 100%;
    flex-grow: 1;
}

.content-qr .div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: var(--goopay-1-spacing-ml) 0px var(--goopay-1-spacing-ml) 0px;
    position: relative;
    flex: 1;
    align-self: stretch;
    width: 100%;
    flex-grow: 1;
}

.content-qr .frame-2 {
    display: flex;
    flex-direction: column;
    width: 120px;
    align-items: center;
    gap: 5px;
    position: relative;
    flex: 0 0 auto;
}

.content-qr .div-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--goopay-1-spacing-s);
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
}

.content-qr .text-wrapper {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    margin-left: -88.5px;
    margin-right: -88.5px;
    font-family: var(--font-normal-text-font-family);
    font-weight: var(--font-normal-text-font-weight);
    color: #ffffff;
    font-size: var(--font-normal-text-font-size);
    text-align: center;
    letter-spacing: var(--font-normal-text-letter-spacing);
    line-height: var(--font-normal-text-line-height);
    font-style: var(--font-normal-text-font-style);
}

.content-qr .img {
    position: relative;
    width: 33px;
    height: 44px;
    margin-bottom: -11px;
}

.content-qr .frame-3 {
    display: flex;
    width: 329px;
    align-items: center;
    justify-content: space-around;
    position: relative;
    flex: 0 0 auto;
}

.content-qr .combined-shape {
    position: absolute;
    width: 360px;
    height: 400px;
    top: -14px;
}

.content-qr .frame-4 {
    display: flex;
    flex-direction: column;
    width: 328px;
    align-items: center;
    padding: var(--goopay-1-spacing-ml) 0px var(--goopay-1-spacing-ml) 0px;
    position: relative;
}

.content-qr .frame-5 {
    position: relative;
    width: 265px;
    height: 100px;
    background-image: url(../../../public/img/qrcode/image-22.png);
    background-size: cover;
    background-position: 50% 50%;
}

.content-qr .group {
    position: relative;
    width: 250px;
    height: 230px;
}

.content-qr .frame-6 {
    display: flex;
    align-items: center;
    gap: var(--goopay-1-spacing-m);
    padding: 0px var(--goopay-1-spacing-ml) 0px var(--goopay-1-spacing-ml);
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
}

.content-qr .button {
    all: unset;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--goopay-1-spacing-xxs);
    padding: var(--goopay-1-spacing-m) var(--goopay-1-spacing-sm) var(--goopay-1-spacing-m) var(--goopay-1-spacing-sm);
    position: relative;
    flex: 1;
    flex-grow: 1;
    background-color: var(--loyalty-colormain);
    border-radius: var(--goopay-1-radius-m);
    border: 1px solid;
    border-color: var(--goopay-1-base-base-white);
}

.content-qr .img-2 {
    position: relative;
    width: 20px;
    height: 20px;
}

.content-qr .text-wrapper-2 {
    position: relative;
    width: fit-content;
    font-family: var(--font-normal-text-font-family);
    font-weight: var(--font-normal-text-font-weight);
    color: var(--goopay-1-base-base-white);
    font-size: var(--font-normal-text-font-size);
    letter-spacing: var(--font-normal-text-letter-spacing);
    line-height: var(--font-normal-text-line-height);
    font-style: var(--font-normal-text-font-style);
}

.content-qr .bg {
    position: absolute;
    width: 100%;
    height: 671px;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
}
