.logo-animate {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
}

.video-login {
  position: absolute;
  width: 230px;
  top: 185px;
  left: calc(50% - 115px);
  border-radius: 20px;
  background-color: transparent;
}

@keyframes slideFromTo {
  0% {
    transform: translate(calc(50% - 115px), calc(50% - 115px)); /* Starting position */
  }
  100% {
    transform: translate(115px, calc(50% - 172px)); /* Ending position */
  }
}

.animated-element {
  width: 230px;
  animation: slideFromTo 1s ease-in-out forwards; /* Adjust duration and easing as needed */
  position: relative; /* Ensure the element can move relative to its original position */
}



.element-splsh {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  overflow: auto; /* Add scrollbars if content overflows */
  border: 1px solid #ccc; /* Optional: Border for visibility */
}

.element-splsh .element-splsh-wrapper {
  position: relative;
  height: 600px; /* Content height larger than container height */
  background: white;
}

.element-splsh .overlap {
  position: relative;
  height: 852px;
  background: linear-gradient(180deg, rgb(233, 30, 68) 0%, rgb(239, 82, 34) 100%);
}

.element-splsh .overlap-group {
  position: absolute;
  width: 235px;
  height: 229px;
  top: 60px;
  left: calc(50% - 118px);
  background-color: #ffffff;
  border-radius: 10px;
}

.element-splsh .logo-futa-splash {
  position: relative;
  width: 205px;
  height: 206px;
  top: 11px;
  left: 22px;
}

.element-splsh .square {
  position: relative;
  width: 190px;
  height: 157px;
  top: 16px;
}

.element-splsh .ch-ng-tr-nh-kh-ch-h {
  position: absolute;
  width: 100%;
  top: 316px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 23px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  padding: 0 30px;
}

.element-splsh .text-wrapper-sp {
  font-weight: 700;
}

.element-splsh .span {
  font-weight: 700;
  font-size: 13px;
}

.element-splsh .text-wrapper-2 {
  font-weight: 500;
  font-size: 12px;
}

.element-splsh .login {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 15px;
  padding: 40px 35px;
  position: absolute;
  top: 512px;
  left: 0;
  background-color: #ffffff;
  border-radius: 20px 20px 0px 0px;
  overflow: hidden;
}

.element-splsh .div {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: var(--font-h2-font-family);
  font-weight: var(--font-h2-font-weight);
  color: #000000;
  font-size: var(--font-h2-font-size);
  text-align: center;
  letter-spacing: var(--font-h2-letter-spacing);
  line-height: var(--font-h2-line-height);
  font-style: var(--font-h2-font-style);
}

.element-splsh .group {
  position: relative;
  width: 100%;
  height: 50px;
  margin-right: -1.5px;
}

.element-splsh .div-wrapper {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  border: 1px solid;
  border-color: var(--loyalty-colorgrey);
}

.element-splsh .text-wrapper-3 {
  position: absolute;
  width: 100%;
  top: 14px;
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: var(--loyalty-colordark-gery);
  font-size: var(--font-normal-text-font-size);
  text-align: center;
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-style: var(--font-normal-text-font-style);
  margin: 0 auto;
}

.element-splsh .btn {
  all: unset;
  box-sizing: border-box;
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 40px;
  background-color: var(--loyalty-colormain);
  border-radius: 20px;
  overflow: hidden;
}
.element-splsh .disabled {
  background-color: #9e9e9e;
}

.element-splsh .text {
  position: absolute;
  width: 100%;
  top: 10px;
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: #ffffff;
  font-size: var(--font-normal-text-font-size);
  text-align: center;
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-style: var(--font-normal-text-font-style);
}

.element-splsh .b-ng-vi-c-nh-n-ti-p {
  position: relative;
  align-self: stretch;
  height: 56px;
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: transparent;
  font-size: var(--font-normal-text-font-size);
  text-align: center;
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-style: var(--font-normal-text-font-style);
}

.element-splsh .text-wrapper-4 {
  color: #9e9e9e;
  font-family: var(--font-normal-text-font-family);
  font-style: var(--font-normal-text-font-style);
  font-weight: var(--font-normal-text-font-weight);
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-size: var(--font-normal-text-font-size);
}

.element-splsh .text-wrapper-5 {
  color: #000000;
  font-family: var(--font-normal-text-font-family);
  font-style: var(--font-normal-text-font-style);
  font-weight: var(--font-normal-text-font-weight);
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-size: var(--font-normal-text-font-size);
}

.element-splsh .text-wrapper-6 {
  color: #006ebe;
  font-family: var(--font-normal-text-font-family);
  font-style: var(--font-normal-text-font-style);
  font-weight: var(--font-normal-text-font-weight);
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-size: var(--font-normal-text-font-size);
}