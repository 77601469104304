.bottom-nav {
  position: fixed;
  width: 100%;
  height: 50px;
  left: 0;
  background-color: var(--loyalty-colorwhite);
  box-shadow: 0px -5px 4px #0000000d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  z-index: 1;
  bottom: 0;
}

.bg-active {
  background-color: var(--loyalty-colorsub-2);
  border-radius: 20px;
  .label {
    color: var(--loyalty-colorsub-3);
  }
}

.nav-item {
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: 120px;
  height: 40px;
  .label {
    font-family: var(--font-small-text-font-family);
    font-weight: var(--font-small-text-font-weight);
    font-size: var(--font-small-text-font-size);
    letter-spacing: var(--font-small-text-letter-spacing);
    line-height: var(--font-small-text-line-height);
    font-style: var(--font-small-text-font-style);
    text-align: center;
    white-space: nowrap;
  }
}

.icon-bot-nav {
  width: 24px;
  height: 24px;
}