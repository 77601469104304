.head {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  align-items: flex-end;
  z-index: 1;
}

.head-bg {
  background-image:url("../../../public/img/head-bg.png");
}

.layer {
  width: 23px;
  height: 21px;
}

.loyalty-program {
  font-family: var(--font-h1-font-family);
  font-weight: var(--font-h1-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-h1-font-size);
  text-align: center;
  letter-spacing: var(--font-h1-letter-spacing);
  line-height: var(--font-h1-line-height);
  font-style: var(--font-h1-font-style);
}

.group {
  width: 29px;
  height: 28px;
}

.header-div {
  position: relative;
  height: 28px;
}

.layer-2 {
  position: absolute;
  width: 23px;
  height: 23px;
  top: 5px;
  left: 0;
}

.thong-bao {
  position: absolute;
  width: 17px;
  height: 17px;
  top: 0;
  left: 12px;
  background-color: var(--colorred);
  border-radius: 7px;
  overflow: hidden;
}

.text-wrapper {
  position: absolute;
  top: 1px;
  left: 5px;
  font-family: "Inter", Helvetica;
  font-weight: 700;
  color: #ffffff;
  font-size: 10px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}