/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#app {
  overflow: hidden;
}

.marquee-container {
  overflow: hidden;  /* Hide the overflow to create the scrolling effect */
  white-space: nowrap;  /* Prevents text from wrapping to the next line */
  box-sizing: border-box;
  margin: 0 auto;
}

.marquee {
  display: inline-block;
  animation: marquee 10s linear infinite;  /* Infinite scrolling animation */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);  /* Start the text from the right */
  }
  100% {
    transform: translateX(-100%);  /* Move the text to the left */
  }
}

.gp-screen {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--loyalty-colorwhite);
  overflow: hidden;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideInFromRight 0.5s ease-in-out forwards;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-top {
  animation: slideInFromTop 0.5s ease-in-out forwards;
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100%); /* Start below the viewport */
    opacity: 0; /* Optional: Start with element invisible */
  }
  100% {
    transform: translateY(0); /* End at its normal position */
    opacity: 1; /* Optional: End fully visible */
  }
}

.slide-in-bottom {
  animation: slideFromBottom 0.5s ease-in-out forwards;
  position: relative; /* Ensures the element moves relative to its normal position */
}

input {
  all: unset; /* Resets all properties */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  width: 100%; /* Make input take full width of its container */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  border: none; /* Remove any default border */
  background: none; /* Remove any default background */
  font-family: inherit; /* Inherit font family from parent */
  font-size: inherit; /* Inherit font size from parent */
  line-height: inherit; /* Inherit line height from parent */
  color: inherit; /* Inherit text color from parent */
  outline: none; /* Remove the outline on focus */
  appearance: none; /* Remove browser-specific styling */
}

input::placeholder {
  color: inherit; /* Inherit placeholder text color from parent */
  opacity: 1; /* Ensure placeholder text is fully opaque */
}

button:disabled {
  background-color: #ccc;
}

