.section {
}

.section-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.swiper-slide-pp {
  width: 47% !important;
}