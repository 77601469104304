/* ProgressMilestone.css */
.progress-container {
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 15px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #76c7c0;
    border-radius: 15px 0 0 15px;
    transition: width 0.3s ease;
}

.milestone {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}

.milestone-label {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.milestone-dot {
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;
    margin: 0 auto;
}

.vefs-milestone-wrapper {
    --component-height: 50px;
    --line-height: calc(var(--component-height) / 10);
    --dot-size: calc(var(--line-height) * 3.5);
    --milestone-base-color: #cccccc;
    --milestone-progress-color: white;
    --milestone-10-color: #ffbc42;
    --milestone-25-color: #d81159;
    --milestone-50-color: #8f2d56;
    --milestone-80-color: #218380;
    --milestone-100-color: #73d2de;

    .milestone-container {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 90%;
        height: var(--component-height);
        margin: 0 auto;

        .chart-container {
            display: flex;
            flex-flow: column;
            align-items: center;
            flex: 1 50%;

            .line-container {
                position: absolute;
                display: flex;
                align-items: center;
                width: 100%;
                height: var(--dot-size);

                .pr-line {
                    align-self: center;
                    position: absolute;
                    top: calc(var(--dot-size) / 2);
                    transform: translateY(-50%);
                    order: 1;
                    width: 100%;
                    height: var(--line-height);
                    background-color: #FDECE8;
                }

                .pr-line.left {
                    order: 0;
                    background-color: #EF5222;
                    width: 67%;
                    border-radius: 4px;
                }
            }

            .dot-container {
                position: absolute;
                height: var(--dot-size);
                width: 100%;

                .dot {
                    position: absolute;
                    width: var(--dot-size);
                    height: var(--dot-size);
                    border-radius: 50%;
                    background-color: var(--milestone-base-color);
                    transform: translateX(-50%);
                    transition: all 0.25s ease-out;
                }

                .dot.completed {
                    background-color: var(--milestone-progress-color);
                }
            }
        }

        .label-container {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            flex: 1 50%;

            .label {
                position: relative;
                font-size: 0.7rem;
                font-weight: 600;
                color: var(--milestone-base-color);
            }

            .label.colored {
                color: var(--milestone-progress-color);
            }
        }

        .milestones {
            position: absolute;
            transform: translate(-50%, 0);
        }

        .milestones.milestone__0-tick {
            position: absolute;
        }

        .milestones.milestone__0 {
            left: 0;
        }

        .milestones.milestone__0__label {
            left: 0;
            top: 4px;
        }

        .milestones.milestone__0 .dot.colored {
            background-color: #EF5222;
            transition: all 0.25s ease-out;
        }

        .milestones.milestone__10 {
            left: 10%;
        }

        .milestones.milestone__0 .dot.colored {
            background-color: #EF5222;
            transition: all 0.25s ease-out;
        }

        .milestones.milestone__20 {
            left: 20%;
        }
        .milestones.milestone__20__label {
            left: 20%;
            top: 4px;
        }

        .milestones.milestone__33 {
            left: 33%;
        }
        .milestones.milestone__33__label {
            left: 33%;
            top: 4px;
        }

        .milestones.milestone__66 {
            left: 66%;
        }
        .milestones.milestone__66__label {
            left: 66%;
            top: 4px;
        }

        .point-tick{
            width: 18px;
        }

        .milestones.milestone__60 {
            left: 60%;
        }

        .milestones.milestone__60__label {
            left: 60%;
            top: 4px;
        }

        .milestones .img-tv{
            position: absolute;
            width: 97px;
            max-width: 97px;
            top: -10px;
            left: -48px;
        }

        .milestones.milestone__60 .dot.colored {
            background-color: #EF5222;
            transition: all 0.25s ease-out;
        }

        .milestones.milestone__100 {
            left: 100%;
        }

        .milestones.milestone__100_diamond {
            left: 100%;
            top: 4px;
        }

        .milestones.milestone__100_diamond {
            width: 18px;
            left: 100%;
            img {
                width: 18px;
                height: 15px;
            }
        }

        .milestones.milestone__100-point {
            width: 18px;
            left: 100%;
        }

        .milestones.milestone__100 .dot.colored {
            background-color: rgba(115, 210, 222, 1);
            transition: all 0.25s ease-out;
        }
    }
}