.companion-content {
  position: relative;
  height: calc(100vh - 140px);
  top: 60px;
  overflow: hidden;
}

.animateRight{
  position: relative;
  animation: animateright 0.4s;
}
.companion-content-wrapper {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px 15px 90px 15px;
}

.companion-content .frame {
  position: relative;
  height: 580px;
}

.companion-content .overlap-group {
  position: relative;
  height: 88%;
  width: 100%;
}

.companion-content .banner {
  position: absolute;
  width: 100%;
  bottom: 0;
  object-fit: cover;
  z-index: -1;
  margin: 0 auto;
}

.companion-content .banner-img {
  width: 292px;
  margin: 0 auto;
}

.companion-content .celebrate {
  position: absolute;
  width: 100%;
  left: 0;
  top: 130px;
  object-fit: cover;
  z-index: -1;
}

.companion-content .celebrate img {
  height: 300px;
  margin: 0 auto;
}

.companion-content .c-m-n-b-n-v-ng-h-nh {
  width: 100%;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.companion-content .text-wrapper-cp {
  font-weight: var(--font-h1-font-weight);
  font-family: var(--font-h1-font-family);
  font-style: var(--font-h1-font-style);
  letter-spacing: var(--font-h1-letter-spacing);
  line-height: var(--font-h1-line-height);
  font-size: 20px;
}

.companion-content .text-wrapper-cp2 {
  font-weight: var(--font-h1-font-weight);
  font-family: var(--font-h1-font-family);
  font-style: var(--font-h1-font-style);
  letter-spacing: var(--font-h1-letter-spacing);
  line-height: var(--font-h1-line-height);
  font-size: var(--font-h1-font-size);
  /*color: #EF5222;*/
  color: black;
}

.companion-content .span {
  font-weight: var(--font-h3-font-weight);
  font-size: var(--font-h3-font-size);
  font-family: var(--font-h3-font-family);
  font-style: var(--font-h3-font-style);
  letter-spacing: var(--font-h3-letter-spacing);
  line-height: var(--font-h3-line-height);
}

.companion-content .text-wrapper-2 {
  font-size: var(--font-normal-text-font-size);
  font-family: var(--font-normal-text-font-family);
  font-style: var(--font-normal-text-font-style);
  font-weight: var(--font-normal-text-font-weight);
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
}

.companion-content .milestone {
  position: absolute;
  top: 180px;
  width: 100%;
  z-index: 1;
  text-align: center;
}

.companion-content .c2-1 {
  margin: 5px auto;
}


.companion-content .date {
  margin: 5px auto;
  color: #00613D;
  font-size: 20px;
  font-family: "Inter", Helvetica;
  font-weight: bold;
}

.companion-content .desc1 {
  font-weight: bold;
}

.companion-content .title-1 {
  font-size: 20px;
  font-weight: bold;
}
.companion-content .desc-1 {
  font-size: 14px;
}

.companion-content .bottom-desc {
  position: absolute;
  bottom: 30px;
  text-align: center;
}

.companion-content .desc2 {
}

.companion-content .desc-2 {
  font-size: 20px;
}

.companion-content .video-bus {
  position: absolute;
  bottom: -50px;
  width: 100%;
}

.companion-content .video-bus-video {
  width: 90%;
  margin: 0 auto;
}

.companion-content .LOGO-FUTA-GROUP {
  width: 100%;
  object-fit: cover;
  img {
    width: 264px;
    height: 63px;
    margin: 0 auto;
  }
}

.companion-content .btn-ani {
  margin-top: -20px;
  position: relative;
  width: 100%;
  height: 80px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-ani-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.companion-content .rectangle {
  position: absolute;
  width: 330px;
  background-color: var(--loyalty-colormain);
  border-radius: 20px;
  padding: 10px 0;
}

.companion-content .text {
  position: relative;
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: #ffffff;
  font-size: var(--font-normal-text-font-size);
  text-align: center;
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-style: var(--font-normal-text-font-style);
}
