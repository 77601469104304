/* src/components/Toast/Toast.css */
.toast {
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	background: rgb(34,193,195);
	background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
	/*background-color: #333333FF;*/
	color: red;
	padding: 10px 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
	0%, 100% { opacity: 0; }
	10%, 90% { opacity: 1; }
}