
.history-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.history-item-icon{
  margin-right: 10px;
  width: 55px;
  height: 55px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--loyalty-colorgrey);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}
.history-item-info{
  flex-grow: 1;
}
.history-item-point{
  font-weight: var(--font-h3-font-weight);
  color: var(--loyalty-colormain);
  font-size: var(--font-h3-font-size);
  letter-spacing: var(--font-h3-letter-spacing);
  line-height: var(--font-h3-line-height);
  font-style: var(--font-h3-font-style);
}