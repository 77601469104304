.popup {
    position: relative;
    width: 350px;
    height: 350px;
    background-color: var(--loyalty-colorwhite);
    border-radius: 20px;
    overflow: hidden;
}

.popup .overlap-group {
    position: absolute;
    width: 350px;
    height: 278px;
    top: 0;
    left: 0;
}

.popup .popupbg {
    position: absolute;
    width: 350px;
    height: 259px;
    top: 0;
    left: 0;
    object-fit: cover;
}

.popup .text-wrapper {
    position: absolute;
    top: 23px;
    left: 39px;
    font-family: var(--font-bold-font-family);
    font-weight: var(--font-bold-font-weight);
    color: #000000;
    font-size: var(--font-bold-font-size);
    letter-spacing: var(--font-bold-letter-spacing);
    line-height: var(--font-bold-line-height);
    font-style: var(--font-bold-font-style);
}

.popup .image {
    position: absolute;
    width: 57px;
    height: 57px;
    top: 71px;
    left: 95px;
    object-fit: cover;
}

.popup .div {
    position: absolute;
    width: 317px;
    top: 163px;
    left: 20px;
    font-family: var(--font-normal-text-font-family);
    font-weight: var(--font-normal-text-font-weight);
    color: #000000;
    font-size: var(--font-normal-text-font-size);
    text-align: center;
    letter-spacing: var(--font-normal-text-letter-spacing);
    line-height: var(--font-normal-text-line-height);
    font-style: var(--font-normal-text-font-style);
}

.popup .group {
    position: absolute;
    width: 18px;
    height: 14px;
    top: 25px;
    left: 16px;
}

.popup .rectangle {
    width: 3px;
    height: 14px;
    top: 127px;
    left: 121px;
    position: absolute;
    background-color: var(--loyalty-colormain);
}

.popup .rectangle-2 {
    width: 180px;
    height: 2px;
    top: 141px;
    left: 34px;
    position: absolute;
    background-color: var(--loyalty-colormain);
}

.popup .btn {
    all: unset;
    box-sizing: border-box;
    top: 238px;
    left: 10px;
    background-color: var(--loyalty-colormain);
    position: absolute;
    width: 330px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
}

.popup .text {
    color: #ffffff;
    position: absolute;
    width: 231px;
    top: 10px;
    left: 49px;
    font-family: var(--font-normal-text-font-family);
    font-weight: var(--font-normal-text-font-weight);
    font-size: var(--font-normal-text-font-size);
    text-align: center;
    letter-spacing: var(--font-normal-text-letter-spacing);
    line-height: var(--font-normal-text-line-height);
    font-style: var(--font-normal-text-font-style);
}

.popup .btn-outline {
    top: 290px;
    left: 10px;
    border: 1px solid;
    border-color: var(--loyalty-colormain);
    position: absolute;
    width: 330px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
}

.popup .text-2 {
    color: var(--loyalty-colorsub-3);
    position: absolute;
    width: 231px;
    top: 10px;
    left: 49px;
    font-family: var(--font-normal-text-font-family);
    font-weight: var(--font-normal-text-font-weight);
    font-size: var(--font-normal-text-font-size);
    text-align: center;
    letter-spacing: var(--font-normal-text-letter-spacing);
    line-height: var(--font-normal-text-line-height);
    font-style: var(--font-normal-text-font-style);
}
