.home-content {
  position: relative;
  height: calc(100vh - 140px);
  top: 60px;
  overflow: hidden;
}

.content-wrapper {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px 15px 90px 15px;
}

.home-content .frame {
  position: relative;
  background-color: var(--loyalty-colorsub-2);
  border-radius: 23px;
}

.mask-init {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.mask-init-wp {
  position: absolute;
  top: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.round-arrow {
  margin-top: 20px;
}

.mask-init-bubble {
  text-align: center;
  width: 90%;
  padding: 5px;
  color: white;
}

.mask-init-button {
  color: white;
  border: 1px solid white;
  border-radius: 20px;
  padding: 2px 10px;
  width: 138px;
}

.mask-init-history {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.home-content .overlap {
  position: relative;
  padding: 8px;
}

/*Card*/
.home-content .card {
  position: relative;
  height: 220px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 4px 4px #ffbba6;
  background-image: url(../../../../public/img/card-bg.png);
  padding-top: 20px;
  background-size: 100% 100%;
}

.home-content .card-info {
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.home-content .card-left {
}

.home-content .card-right {
}

.home-content .card-mask {
  position: absolute;
}

.home-content .frame-2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  background-color: var(--loyalty-colormain);
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content .p {
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: var(--colorwhite);
  font-size: var(--font-normal-text-font-size);
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-style: var(--font-normal-text-font-style);
}

.home-content .overlap-3 {
  height: 30px;
}

.home-content .group-2 {
  height: 30px;
}

.home-content .rectangle-wrapper {
  position: relative;
  width: 215px;
  height: 10px;
  background-image: url(../../../../public/img/rectangle-1.png);
  background-size: 100% 100%;
}

.home-content .rectangle {
  position: relative;
  width: 109px;
  height: 8px;
  top: 1px;
  left: 1px;
  background-color: var(--loyalty-colormain);
  border-radius: 4px;
}

.home-content .text-wrapper-4 {
  font-family: var(--font-bold-font-family);
  font-weight: var(--font-bold-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-bold-font-size);
  letter-spacing: var(--font-bold-letter-spacing);
  line-height: 23px;
  font-style: var(--font-bold-font-style);
}

.home-content .text-wrapper-5 {
  position: absolute;
  top: 0;
  right: 5px;
  font-family: "Inter", Helvetica;
  font-weight: 700;
  color: var(--loyalty-colorsub-3);
  font-size: 8px;
  letter-spacing: 0;
  line-height: normal;
}

.home-content .rank-qr {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
}

.home-content .text-rank {
  width: 130px;
}

.home-content .text-wrapper-6 {
  width: 88px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--loyalty-colorwhite);
  font-size: 10px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.home-content .group-3 {
  width: 9px;
  height: 8px;
}

.home-content .text-wrapper-7 {
  margin-bottom: 5px;
  font-family: var(--font-h1-font-family);
  font-weight: var(--font-h1-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-h1-font-size);
  letter-spacing: var(--font-h1-letter-spacing);
  line-height: var(--font-h1-line-height);
  white-space: nowrap;
  font-style: var(--font-h1-font-style);
}

.home-content .QR {
  width: 54px;
  height: 20px;
  background-color: #ffffff4c;
  border-radius: 10px;
  display: flex;
  justify-content: center; align-items: center;
  gap: 5px;
}

.home-content .text-wrapper-8 {
  font-family: var(--font-normal-text-font-family);
  font-weight: var(--font-normal-text-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-normal-text-font-size);
  letter-spacing: var(--font-normal-text-letter-spacing);
  line-height: var(--font-normal-text-line-height);
  font-style: var(--font-normal-text-font-style);
}

.home-content .union {
  width: 12px;
  height: 12px;
}

.home-content .overlap-4 {
  position: relative;
  width: 87px;
  height: 136px;
}

.home-content .mem-level {
  position: absolute;
  width: 87px;
  height: 118px;
  top: 18px;
  left: 0;
}

.home-content .mask-group-2 {
  position: absolute;
  width: 43px;
  height: 43px;
  top: 0;
  left: 22px;
}

/*End Card*/

.home-content .t-ch-i-m-khi-s-d {
  width: 95px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.home-content .group {
  width: 75px;
  height: 62px;
}

.home-content .overlap-group {
  position: relative;
  height: 62px;
}

.home-content .mask-group {
  position: absolute;
  width: 62px;
  height: 62px;
  top: 0;
  left: 0;
}

.home-content .div-wrapper {
  position: absolute;
  width: 26px;
  height: 16px;
  top: 8px;
  left: 49px;
  background-color: var(--loyalty-colormain);
  border-radius: 8px;
  overflow: hidden;
}

.home-content .div {
  position: absolute;
  top: -2px;
  left: 5px;
  font-family: var(--font-h3-font-family);
  font-weight: var(--font-h3-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-h3-font-size);
  text-align: center;
  letter-spacing: var(--font-h3-letter-spacing);
  line-height: var(--font-h3-line-height);
  font-style: var(--font-h3-font-style);
}

.home-content .image-wrapper {
  width: 63px;
  height: 63px;
  background-color: var(--loyalty-colorwhite);
  border-radius: 31.5px;
}

.home-content .img-ct {
  width: 48px;
  height: 48px;
  mix-blend-mode: multiply;
  object-fit: cover;
}

.home-content .frame-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
  background-color: var(--loyalty-colorwhite);
  border-radius: 20px;
}

.home-content .tich-diem-lich-su {
  position: relative;
  width: 100%;
}

.home-content .point-banner {
  width: 100%;
}

.home-content .overlap-5 {
  position: relative;
  height: 113px;
}

.home-content .banner {
  position: absolute;
  right: 0;
  bottom: 0;
}

.home-content .text-wrapper-9 {
  width: 258px;
  font-family: "Inter", Helvetica;
  font-weight: 700;
  color: var(--loyalty-colorwhite);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 5px;
}

.home-content .kh-m-ph-h-nh-tr-nh {
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: var(--loyalty-colorwhite);
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
  margin-bottom: 30px;
}

.home-content .overlap-6 {
  position: absolute;
  width: 132px;
  height: 172px;
  top: -22px;
  left: -30px;
}

.home-content .group-wrapper {
  position: absolute;
  width: 117px;
  height: 132px;
  top: 40px;
  left: 0;
}

.home-content .OBJECTS-wrapper {
  height: 132px;
}

.home-content .OBJECTS {
  width: 117px;
  height: 132px;
}

.home-content .overlap-group-wrapper {
  height: 132px;
  background-image: url(../../../../public/img/rectangle.png);
  background-size: 100% 100%;
}

.home-content .clip-path-group-wrapper {
  position: relative;
  width: 103px;
  height: 101px;
  top: 5px;
  left: 9px;
  background-image: url(../../../../public/img/vector.png);
  background-size: 100% 100%;
}

.home-content .clip-path-group {
  position: absolute;
  width: 103px;
  height: 101px;
  top: 0;
  left: 0;
}

.home-content .OBJECTS-2 {
  position: absolute;
  width: 80px;
  height: 45px;
  top: 0;
  left: 4px;
}

.home-content .frame-4 {
  position: absolute;
  width: 37px;
  height: 45px;
  top: 20px;
  left: 95px;
}

.home-content .btn-arrow {
  position: absolute;
  right: 0;
  bottom: 7px;
  width: 118px;
  height: 18px;
}

.home-content .frame-5 {
  position: relative;
  height: 18px;
  background-color: var(--loyalty-colorsub);
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content .text-wrapper-10 {
  width: 93px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--loyalty-colorsub-3);
  font-size: 10px;
  text-align: center;
  letter-spacing: 0;
  line-height: 1px;
}

.home-content .group-4 {
  width: 10px;
  height: 11px;
}

.home-content .coin {
  position: absolute;
  width: 43px;
  height: 56px;
  top: 0;
  left: 20px;
}

.home-content .frame-6 {
  position: relative;
  width: 100%;
  height: 25px;
  margin-top: 15px;
}

.home-content .text-wrapper-11 {
  width: 162px;
  font-family: var(--font-h2-font-family);
  font-weight: var(--font-h2-font-weight);
  color: var(--loyalty-colorblack);
  font-size: var(--font-h2-font-size);
  letter-spacing: var(--font-h2-letter-spacing);
  line-height: var(--font-h2-line-height);
  font-style: var(--font-h2-font-style);
}

.home-content .group-5 {
  position: absolute;
  width: 14px;
  height: 13px;
  top: 4px;
  left: 171px;
}

.home-content .frame-7 {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  align-self: stretch;
  width: 100%;
  overflow-x: scroll;
  margin-top: 20px;
}

.home-content .btn-wrapper {
  position: relative;
  width: 297px;
  height: 120px;
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(210, 156, 60), rgb(231, 189, 116)) 1;
  background: linear-gradient(180deg, rgb(92.32, 92.32, 92.32) 0%, rgb(35.31, 35.31, 35.31) 100%);
  background-image: url(../../../../public/img/app-banner-297x120-1.png);
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 10px;
  overflow: hidden;
}

.home-content .button {
  all: unset;
  box-sizing: border-box;
  position: relative;
  width: 74px;
  height: 20px;
  top: 92px;
  left: 214px;
  background-color: var(--loyalty-colorsub-2);
  border-radius: 10px;
  overflow: hidden;
}

.home-content .text-wrapper-12 {
  position: absolute;
  width: 67px;
  top: 3px;
  left: 3px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--loyalty-colorsub-3);
  font-size: 10px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.home-content .banner-2 {
  position: relative;
  width: 297px;
  height: 120px;
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(210, 156, 60), rgb(231, 189, 116)) 1;
  background: linear-gradient(180deg, rgb(92.32, 92.32, 92.32) 0%, rgb(35.31, 35.31, 35.31) 100%);
  background-image: url(../../../../public/img/app-banner-2-297x120-1.png);
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 10px;
  overflow: hidden;
}

.home-content .banner-3 {
  position: relative;
  width: 297px;
  height: 120px;
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(210, 156, 60), rgb(231, 189, 116)) 1;
  background: linear-gradient(180deg, rgb(92.32, 92.32, 92.32) 0%, rgb(35.31, 35.31, 35.31) 100%);
  background-image: url(../../../../public/img/app-banner-2-297x120-1-1.png);
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 10px;
  overflow: hidden;
}

.home-content .xem-them {
  position: relative;
  width: 158px;
  height: 120px;
  background-color: var(--loyalty-colorwhite);
  border-radius: 10px;
  overflow: hidden;
}

.home-content .frame-8 {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 11px;
  left: 39px;
}

.home-content .text-wrapper-13 {
  position: absolute;
  top: 97px;
  left: 44px;
  font-family: var(--font-h3-font-family);
  font-weight: var(--font-h3-font-weight);
  color: var(--loyalty-colorblack);
  font-size: var(--font-h3-font-size);
  letter-spacing: var(--font-h3-letter-spacing);
  line-height: var(--font-h3-line-height);
  font-style: var(--font-h3-font-style);
}

.home-content .text-wrapper-14 {
  position: absolute;
  top: 3px;
  left: 20px;
  font-family: var(--font-h2-font-family);
  font-weight: var(--font-h2-font-weight);
  color: var(--loyalty-colorblack);
  font-size: var(--font-h2-font-size);
  letter-spacing: var(--font-h2-letter-spacing);
  line-height: var(--font-h2-line-height);
  white-space: nowrap;
  font-style: var(--font-h2-font-style);
}

.home-content .group-6 {
  position: absolute;
  width: 14px;
  height: 13px;
  top: 7px;
  left: 157px;
}

.home-content .uu-dai-doi-tac {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 10px 14px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  overflow-x: scroll;
}

.home-content .uu-dai {
  position: relative;
  width: 158px;
  height: 250px;
  background-color: var(--loyalty-colorbg-color);
  border-radius: 10px;
  overflow: hidden;
}

.home-content .image-2 {
  width: 161px;
  height: 139px;
  top: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
}

.home-content .text-wrapper-15 {
  position: absolute;
  top: 148px;
  left: 30px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #000000;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.home-content .text-wrapper-16 {
  position: absolute;
  top: 192px;
  left: 8px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #000000;
  font-size: var(--font-small-text-font-size);
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.home-content .text-wrapper-17 {
  position: absolute;
  top: 172px;
  left: 8px;
  font-family: var(--font-bold-font-family);
  font-weight: var(--font-bold-font-weight);
  color: #000000;
  font-size: var(--font-bold-font-size);
  letter-spacing: var(--font-bold-letter-spacing);
  line-height: var(--font-bold-line-height);
  font-style: var(--font-bold-font-style);
}

.home-content .nhi-m-v-wrapper {
  all: unset;
  box-sizing: border-box;
  position: absolute;
  width: 80px;
  height: 24px;
  top: 217px;
  left: 39px;
  background-color: var(--loyalty-colormain);
  border-radius: 12px;
  overflow: hidden;
}

.home-content .nhi-m-v {
  position: absolute;
  width: 70px;
  top: 3px;
  left: 4px;
  font-family: var(--font-small-text-font-family);
  font-weight: var(--font-small-text-font-weight);
  color: #ffffff;
  font-size: var(--font-small-text-font-size);
  text-align: center;
  letter-spacing: var(--font-small-text-letter-spacing);
  line-height: var(--font-small-text-line-height);
  font-style: var(--font-small-text-font-style);
}

.home-content .group-7 {
  position: absolute;
  width: 18px;
  height: 16px;
  top: 147px;
  left: 8px;
}

.home-content .overlap-group-2 {
  position: relative;
  width: 16px;
  height: 16px;
  background-color: var(--loyalty-colorsub-3);
  border-radius: 8px;
}

.home-content .text-wrapper-18 {
  position: absolute;
  top: 0;
  left: 3px;
  font-family: "Inter", Helvetica;
  font-weight: 700;
  color: var(--loyalty-colorwhite);
  font-size: 13px;
  letter-spacing: 0;
  line-height: normal;
}

.home-content .g-wrapper {
  position: relative;
  width: 16px;
  height: 16px;
  background-color: var(--loyalty-colorwhite);
  border-radius: 8px;
}

.home-content .image-3 {
  width: 11px;
  height: 11px;
  top: 159px;
  left: 189px;
  position: absolute;
  object-fit: cover;
}

.home-content .xem-them-2 {
  position: relative;
  width: 158px;
  height: 230px;
  background-color: var(--loyalty-colorwhite);
  border-radius: 10px;
  overflow: hidden;
}

.home-content .frame-9 {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 63px;
  left: 39px;
}

.home-content .text-wrapper-19 {
  position: absolute;
  top: 150px;
  left: 44px;
  font-family: var(--font-h3-font-family);
  font-weight: var(--font-h3-font-weight);
  color: var(--loyalty-colorblack);
  font-size: var(--font-h3-font-size);
  letter-spacing: var(--font-h3-letter-spacing);
  line-height: var(--font-h3-line-height);
  font-style: var(--font-h3-font-style);
}
